import React, { useRef, useLayoutEffect } from "react";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import { gsap } from "gsap";
import { PrismicRichText } from "@prismicio/react";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";

const HomePage = ({ data }) => {
  if (!data) return null;
  const home = data.page.data;
  // console.log(home);
  const fireflys = 20;
  const homePageRef = useRef();

  useLayoutEffect(() => {
    const homeCtx = gsap.context(() => {
      const tl = gsap.timeline();
      tl.from(".home-greeting", {
        y: 20,
        opacity: 0,
        duration: 0.8,
        ease: "power1.out",
      });
      tl.from(
        ".home-name",
        { y: 35, opacity: 0, duration: 1, ease: "power1.out" },
        "-=0.6"
      );
      tl.from(
        ".home-job-title",
        { y: 35, opacity: 0, duration: 1, ease: "power1.out" },
        "-=0.6"
      );
      tl.from(
        ".home-subtitle",
        { y: 30, opacity: 0, duration: 0.8, ease: "power1.out" },
        "-=0.6"
      );
      tl.from(
        ".contact-btn",
        { opacity: 0, duration: 1, ease: "power1.out" },
        "-=0.2"
      );
    }, homePageRef);
    return () => {
      homeCtx.revert();
    }; // animation cleanup
  }, []);

  return (
    <>
      <Seo page={data.page} />
      <Layout>
        <Container
          fluid
          className="homepage d-flex flex-column justify-content-center pt-5 ps-4 ps-sm-5 pe-4 pe-sm-7"
        >
          <div ref={homePageRef}>
            <h4 className="home-greeting text-secondary">{home.greeting}</h4>
            <h1 className="home-name">{home.name}</h1>
            <h2 className="home-job-title">{home.job_title}</h2>
            <div className="home-subtitle mb-6">
              <PrismicRichText field={home.subtitle.richText} />
            </div>
            <Anchor
              href={home.button_link.url}
              className="contact-btn glow-on-hover"
            >
              {home.button_label}
            </Anchor>
          </div>
        </Container>
        {/* Fireflys effect */}
        {[...Array(fireflys)].map((firefly, i) => {
          // eslint-disable-next-line react/no-array-index-key
          return <div className="firefly" key={i} />;
        })}
      </Layout>
    </>
  );
};

export const query = graphql`
  query homePageQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicHomepage(lang: { eq: $lang }) {
      alternate_languages {
        type
        lang
      }
      lang
      type
      url
      data {
        meta_description
        meta_title
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        greeting
        name
        job_title
        subtitle {
          richText
        }
        button_label
        button_link {
          type
          uid
          url
          target
        }
      }
    }
  }
`;

export default HomePage;
